import { useState } from "react";
import { Header, ScrollProps } from "../../components/Header";

import {
    Container,
    TitlePage,
    ContentHeader,
    ComplementHeader,
    ContentHeaderMobile
} from "./styles";

import { CardInfo } from "../../components/CardInfo";
import { useNavigate } from "react-router-dom";
import { IoChevronBackOutline } from "react-icons/io5";
import { LoadSpinner2 } from "../../components/LoadSpinner2";
import { useAuth } from "../../context/auth";
import { GoToTop } from "../../GoToTop";
import { useSearch } from "../../context/search";
import { useSearchAdvanced } from "../../context/searchAdvanced";
import { Footer } from "../../components/Footer";
import { SadAnimation } from "../../components/SadAnimation";
import { LoadSpinnerMoreCards } from "../../components/LoadSpinnerMoreCards";
import { IoMdArrowDropdown } from "react-icons/io";

export function SearchResult() {

    const {
        user
    } = useAuth();

    const {
        setCategorySelected,
        setOperationSelected,
        setSearch,
        loading,
        building,
        loadingScroll,
        buildingTotal,
        scrollPages
    } = useSearch()

    const {
        buildingAdvanced,
        setAdvancedOperationAluguel,
        setAdvancedOperationLancamento,
        setAdvancedOperationVenda,
        setAdvancedSearchOperation,
        setCardsCategoriesSelect,
        setCity,
        setDistrict,
        setState,
        setComodoBathrooms,
        setComodoBedRooms,
        setComodoSuites,
        setComodoVagas,
        setRangerPriceRentFinal,
        setRangerPriceRentInitial,
        setRangerPriceSaleFinal,
        setRangerPriceSaleInitial,
        setTypeBuildsSelected,
        loadingAdvanced,
        buildingAdvancedTotal,
        scrollPagesAdvanced,
        loadingAdvancedPage
    } = useSearchAdvanced()



    const backToTheHome = () => {
        //search comum
        setSearch('');
        setOperationSelected([]);
        setCategorySelected('');

        //search advanced
        setAdvancedOperationAluguel(true)
        setAdvancedOperationLancamento(true)
        setAdvancedOperationVenda(true)
        setAdvancedSearchOperation([])
        setCardsCategoriesSelect([])
        setCity('')
        setDistrict('')
        setState('')
        setComodoBathrooms(0)
        setComodoBedRooms(0)
        setComodoSuites(0)
        setComodoVagas(0)
        setRangerPriceRentFinal(0)
        setRangerPriceRentInitial(0)
        setRangerPriceSaleFinal(0)
        setRangerPriceSaleInitial(0)
        setTypeBuildsSelected([])

        navigate('/');
    }



    const [scrollTop, setScrollTop] = useState<ScrollProps>({ y: 0, lastY: 0 } as ScrollProps);
    const [show, setShow] = useState(false);

    const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
        const scrollTop = event.currentTarget.scrollTop;
        setScrollTop(prevState => { return { y: scrollTop, lastY: prevState.y } })
    }

    const navigate = useNavigate()


    return (
        <Container onScroll={handleScroll}>
            <Header show={show} setShow={setShow} scroll={scrollTop} />
            <ContentHeader>
                <button onClick={backToTheHome}>
                    <IoChevronBackOutline className="icon" />
                    Volta para início
                </button>
                <TitlePage>Resultados da pesquisa</TitlePage>
                <ComplementHeader />
            </ContentHeader>
            <ContentHeaderMobile>
                <div className="content-button-back-home">
                    <button onClick={backToTheHome}>
                        <IoChevronBackOutline className="icon" />
                        Volta para início
                    </button>
                </div>
                <div className="content-title">
                    <h2>Resultados da pesquisa</h2>
                </div>
            </ContentHeaderMobile>
            {
                (loading || loadingAdvanced) &&
                <LoadSpinner2 />
            }
            {
                !loading &&
                <>
                    {
                        buildingTotal?.length > 0 ?
                            <>
                                <div className="content-search">
                                    {
                                        buildingTotal && buildingTotal.map(b => (
                                            <CardInfo
                                                id={b.id}
                                                key={b.id}
                                                descricao={b.descricao}
                                                valor_aluguel={b.is_aluguel}
                                                is_aluguel={b.is_aluguel}
                                                is_venda={b.is_venda}
                                                latitude={b.latitude}
                                                longitude={b.longitude}
                                                periodo_aluguel={b.periodo_aluguel}
                                                valor_venda={b.valor_venda}
                                                arquivos={b.arquivos}
                                                onClick={() => navigate(user ? `/perfil/${user.id_corretor}/imovel/${b.id}` : `/imovel/${b.id}`)}
                                                idBuilding={b.id}
                                                is_fixado={b.is_fixado}
                                                is_destaque={b.is_destaque}
                                                is_lancamento={b.is_lancamento}
                                                bairro={b.bairro}
                                                cidade={b.cidade}
                                            />
                                        ))
                                    }
                                </div>
                            </>
                            :
                            <>
                                {
                                    !loadingAdvanced &&
                                    <>
                                        {
                                            buildingAdvancedTotal?.length > 0 ?
                                                <>
                                                    <div className="content-search">
                                                        {
                                                            buildingAdvancedTotal && buildingAdvancedTotal.map(ba => (
                                                                <CardInfo
                                                                    id={ba.id}
                                                                    key={ba.id}
                                                                    descricao={ba.descricao}
                                                                    valor_aluguel={ba.is_aluguel}
                                                                    is_aluguel={ba.is_aluguel}
                                                                    is_venda={ba.is_venda}
                                                                    latitude={ba.latitude}
                                                                    longitude={ba.longitude}
                                                                    periodo_aluguel={ba.periodo_aluguel}
                                                                    valor_venda={ba.valor_venda}
                                                                    arquivos={ba.arquivos}
                                                                    onClick={() => navigate(user ? `/perfil/${user.id_corretor}/imovel/${ba.id}` : `/imovel/${ba.id}`)}
                                                                    idBuilding={ba.id}
                                                                    is_fixado={ba.is_fixado}
                                                                    is_destaque={ba.is_destaque}
                                                                    is_lancamento={ba.is_lancamento}
                                                                    bairro={ba.bairro}
                                                                    cidade={ba.cidade}
                                                                />
                                                            ))
                                                        }
                                                    </div>
                                                </>
                                                :
                                                <div className="no-result">
                                                    <SadAnimation />
                                                    <p >Nenhum item encontrado em sua busca.</p>
                                                </div>
                                        }
                                    </>
                                }
                            </>
                    }
                </>
            }
            {
                buildingTotal?.length > 0 &&
                <>
                    {
                        loadingScroll &&
                        <LoadSpinnerMoreCards />
                    }
                    <div className='div-content-button-view-more'>
                        <button disabled={building.length < 12} className='view-more' onClick={scrollPages}>Ver mais <IoMdArrowDropdown className='arrow-view-more' /></button>

                    </div>
                </>
            }
            {
                buildingAdvancedTotal?.length > 0 &&
                <>
                    {
                        loadingAdvancedPage &&
                        <LoadSpinnerMoreCards />
                    }
                    <div className='div-content-button-view-more'>
                        <button disabled={buildingAdvanced.length < 12} className='view-more' onClick={scrollPagesAdvanced}>Ver mais busca avançada<IoMdArrowDropdown className='arrow-view-more' /></button>

                    </div>
                </>
            }

            <GoToTop />
            <Footer />
        </Container>
    )
}