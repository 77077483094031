import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  
  
  /* padding-bottom: 4rem; */

  .carrosel-construtora {

    padding-bottom: 3rem;

    h1 {
      font-family: 'Isidora Sans', sans-serif;
      text-align: center;
      font-weight: bold;
      color: var(--background-button-component);
    }
    .carroussel-construcors {
      /* padding: 0 3rem; */
      display: flex;
      /* gap: 2rem; */
      width: 100%;
      /* margin-right: 3rem; */

      .slide {
        /* gap: 1rem;
        padding: 0 1rem; */
        /* margin: 0 2rem; */
      }

      .image-and-name {
        margin-right: 15px;
        margin-left: 15px;
        /* border: 1px solid red; */
        padding-top: 10px;
        img {
          box-shadow: 0px 0px 10px rgba(100,100,150,.35);
          height: 300px;
          width: 100%;
          object-fit: contain;
          cursor: pointer;
          padding: 1rem;

          @media screen and (max-width: 1500px) {
           height: 160px;
          }

          @media screen and (max-width: 1300px) {
           height: 150px;
          }
        }

        p {
          margin: 1rem 0;
          text-align: center;
          font-size: 1rem;
        }

        /* @media screen and (max-width:800px){
          img {
            
          }
        } */
      }
      
    }
  }

  .div-content-button-view-more {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .view-more {
      color: var(--color-title-home);
      display: flex;
      align-items: center;
      transition: 0.3s;
      cursor: pointer;
      background-color: transparent;
      border: none;
      :hover {
        filter: brightness(1.8)
      }
    }
    .arrow-view-more {
      color: var(--color-title-home);
      font-size: 2rem;
      
    }
  }

  .informations {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 44px;
    padding: 0 4%;
    color: #fff;
    background-color: #0D2244;

    p {
      display: flex;
      align-items: center;
      gap: .5rem;
      font-size: .9rem; 
      margin: 0; 
    }
    
    & .informations-left {
      display: flex;
      gap: 2rem;
      
      p {
        color: #fff;
        font-weight: 600;
      }
    }

    & .informations-right {
      p { font-weight: 600; }
    }
  }

  .title-recomendation {
    text-align: center;
    margin-top: 3rem;
    margin-bottom: 10px;
    font-size: 2rem;
    font-weight: 800;
    color: var(--color-title-home);

    @media screen and (max-width: 800px) {
      font-size: 1.5rem;
    }
  }

  .title-recomendation-2 {
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 2.5rem;
    font-size: 2rem;
    font-weight: 800;
    color: var(--color-title-home);

    @media screen and (max-width: 800px) {
      font-size: 1.5rem;
    }
  }

  .imoveis {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 1.8rem;
    padding: 20px;
    margin: 0 auto;
    min-height: 1000px;

    .link-imoveis {
      text-decoration: none;
    }
  }

  .news-letter {

    margin-bottom: 4rem;

    .button-view-more {
      display: flex;
      justify-content: flex-end;
      padding-right: 4rem;
      margin-top: -1rem;

      button {
        background-color: transparent;
        border:none;
        display: flex;
        gap: 0.5rem;
        font-size: 0.9rem;
        transition: .3s;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }

      @media screen and (max-width:800px){
        margin-top:1rem;
        padding-right:1rem;
      }
    }

    .content-news-letter {
      display: grid;
      grid-template-columns: repeat(4,1fr);
      gap: 2rem;
      height: auto;
      padding: 2% 4%;
      /* margin-bottom: 2rem; */

      .grid-1 {
        display: grid;
        grid-template-columns: repeat(4,1fr);
        gap: 2rem;
        height: 600px;
        border: 1px solid red;

        .content-grid-1 {
          max-height: 600px;
          display: flex;
          flex-direction: column;
          gap: 2rem;
          div {
            height: 284px;
          }
        }
      }

      @media screen and (max-width:800px){
        display: none;
      }
    }

    .content-news-letter-mobile {
      display: none;
      height: auto;
      padding: 2% 4%;

      @media screen and (max-width:800px){
        display: flex;
      }
    }

    @media screen and (max-width: 800px){
      margin-bottom:1.5rem;
    }
  }

  @media screen and (max-width: 1720px) {
    .imoveis{
      grid-template-columns: repeat(5, 1fr);
    }
  }
  @media screen and (max-width: 1400px) {
    .imoveis{
      grid-template-columns: repeat(4, 1fr);
    }
  }

  @media screen and (max-width: 800px) {
    .imoveis{
      /* padding: 4%; */
      grid-template-columns: repeat(3, 1fr);
    }
  }

  @media screen and (max-width: 950px) {
    .imoveis{
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media screen and (max-width: 860px) {
    .informations {
      p { font-size: .7rem;}

      & .informations-left {
        gap: 1rem;
      }
    }

    
  }

  @media screen and (max-width: 670px) {
    /* padding-top: 15px; */

    .motion-div {
      display: none;
    }
  }

  @media screen and (max-width: 550px) {
    .imoveis{
      grid-template-columns: 1fr;
    }
  }
`;

export const Banner = styled.div`
  width: 100%;
  position: relative;
  cursor:pointer;
  height: calc(100vh - (166px));

  a {
    cursor: pointer;
  }

  img {
    z-index: 5464765467846496467;
    object-fit: cover;
    width: 100%;
    height: calc(100vh - (166px));
  }

  @media screen and (max-width:1132px) {
    height: auto;

    img {
      height: auto;
      object-fit: contain;
    }
  }
`;