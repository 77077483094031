import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    height: 100vh;
    overflow-y: auto;

    .div-content-button-view-more {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            .view-more {
            color: var(--color-title-home);
            display: flex;
            align-items: center;
            transition: 0.3s;
            cursor: pointer;
            background-color: transparent;
            border: none;
            :hover {
                filter: brightness(1.8)
            }
            }
            .arrow-view-more {
            color: var(--color-title-home);
            font-size: 2rem;
            
            }
        }

    .no-result {
        min-height: 80vh;
        p{
            font-size: 1.1rem;
            text-align:center;
            margin-top: 1rem;
            color: var(--color-placeholder-input);
            font-weight: 600;
        }
        
        margin: 4rem 0 0 3rem;

        @media screen and (max-width:800px){
            margin: 4rem 0 0 0;
        }
    }

    .content-search {
        padding: 1rem;
        display: grid;
        min-height:80vh;
        gap: 1rem;
        grid-template-columns: repeat(6,1fr);

       

        @media screen and (max-width:1800px){
            grid-template-columns: repeat(5,1fr);
        }

        @media screen and (max-width:1366px){
            grid-template-columns: repeat(4,1fr);
        }

        @media screen and (max-width:1200px){
            grid-template-columns: repeat(3,1fr);
        }

        @media screen and (max-width:980px){
            grid-template-columns: repeat(2,1fr);
        }

        @media screen and (max-width:580px){
            grid-template-columns: 1fr;
        }
    }

    .tags-result {
        display: flex;
    }
`;

export const TitlePage = styled.h2`
    color: var(--background-button-component);
    text-align: center;
    font-weight: 600;
    margin-top: 24px;
`;

export const ContentHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1rem ;

    button {
        background-color: transparent;
        border: none;
        color: var(--background-button-component);
        display: flex;
        font-size: 0.8rem;
        font-weight: 600;
        align-items: center;
        cursor: pointer;

        .icon {
            font-size: 1.2rem;
            font-weight: 500;
        }

        &:hover {
            text-decoration: underline;
        }
    }

    @media screen and (max-width:800px){
        display: none;
    }
`;

export const ComplementHeader = styled.div`
    width: 1px;
    height: 1px;
    background-color: transparent;
`;

export const ContentHeaderMobile = styled.div`
    .content-button-back-home {
        display: none;
        width: 100%;
        margin-top:1rem;

        button {
            background-color: transparent;
            border: none;
            color: var(--background-button-component);
            display: flex;
            font-size: 0.8rem;
            font-weight: 600;
            align-items: center;
            cursor: pointer;

            .icon {
                font-size: 1.2rem;
                font-weight: 500;
            }

            &:hover {
                text-decoration: underline;
            }
        }

        @media screen and (max-width:800px){
            display: flex;
        }
    }

    .content-title {
        display: none;
        width: 100%;
        margin-top:1rem;

        h2 {
            color: var(--background-button-component);
            text-align: center;
            font-weight: 600;
        }

        @media screen and (max-width:800px){
            display: flex;
            justify-content: center;
        }
    }
`;