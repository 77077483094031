/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { BiSearch, BiSliderAlt } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import { ConfigProvider, Modal, Select, Slider } from 'antd';
// import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/splide.min.css';
import { GoogleCredentialResponse } from '@react-oauth/google';
import jwt_decode from "jwt-decode";
import logo from '../../assets/logo-hub-nogueira-corret.png';
import { IoClose } from 'react-icons/io5';
import { FiLogOut } from 'react-icons/fi';

import {
  Container,
  ContentWrapper,
  ContentModal,
  ContentModalGoogle,
  ContentButtonsModalFilter,
} from './styles';
import { useAuth } from '../../context/auth';
import { FaUserCircle } from 'react-icons/fa';
import { LoadSpinnerLogin } from '../LoadSpinnerLogin';
import { GoToTop } from '../../GoToTop';
import { CardCategorie } from '../CardCategorie';
import { maskBRL } from '../../utils/mask';
import GooglePlacesAutocomplete, { geocodeByAddress } from 'react-google-places-autocomplete';
import { useSearch } from '../../context/search';
import { useSearchAdvanced } from '../../context/searchAdvanced';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import logoNew from '../../assets/logo-hub-new-2.png';
import { AdvancedSearch } from '../AdvancedSearch';

interface IPropHeader {
  visibleFilter?: boolean;
  search?: string;
  setSearch?: (e: string) => void;
  scroll: ScrollProps;
  handleSearch?: () => void;
  show: boolean;
  setShow: (val: boolean) => void;
}

export interface ScrollProps {
  y: number;
  lastY: number;
}

interface IPropsGoogleLogin {
  aud: string;
  azp: string;
  email: string;
  email_verified: boolean;
  exp: number;
  family_name: string;
  given_name: string;
  iat: number;
  iss: string;
  jti: string;
  name: string;
  nbf: number;
  picture: string;
  sub: string;
}

export function Header({ visibleFilter, scroll, handleSearch, show, setShow }: IPropHeader) {

  const navigate = useNavigate();

  const options = [
    { value: '0', label: 'Lançamentos' },
    { value: '1', label: 'Venda' },
    { value: '2', label: 'Aluguel' }
  ]

  const [searchMobile, setSearchMobile] = useState(false);
  const [modal2Open, setModal2Open] = useState(false);

  const [address, setAddress] = useState<any>();

  const [modalLogin, setModalLogin] = useState(false)
  const [modalLogout, setModalLogout] = useState(false)

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [viewPassword, setViewPassword] = useState(false)

  const {
    signIn,
    user,
    signOut,
    loading,
    person,
    categorys,
    typeBuild,
    signInWithEmailAndPassword
  } = useAuth();

  const handleLoginWithEmailAndPassword = useCallback(async () => {
    await signInWithEmailAndPassword({ email, password })
  }, [email, password])

  const typeOptions = typeBuild && typeBuild.map(type => {
    return { value: type.id, label: type.titulo }
  })

  const {
    search,
    setSearch,
    operationSelected,
    categorySelected,
    setCategorySelected,
    setOperationSelected,
    handleSearchBuildings
  } = useSearch();

  const {
    advancedOperationAluguel,
    advancedOperationLacamento,
    advancedOperationVenda,
    advancedSearchOperation,
    cardsCategoriesSelect,
    comodoBathrooms,
    comodoBedRooms,
    comodoSuites,
    comodoVagas,
    rangerPriceRentFinal,
    rangerPriceRentInitial,
    rangerPriceSaleFinal,
    rangerPriceSaleInitial,
    setAdvancedOperationAluguel,
    setAdvancedOperationLancamento,
    setAdvancedOperationVenda,
    setAdvancedSearchOperation,
    setCardsCategoriesSelect,
    setState,
    setCity,
    setDistrict,
    setComodoBathrooms,
    setComodoBedRooms,
    setComodoSuites,
    setComodoVagas,
    setRangerPriceRentFinal,
    setRangerPriceRentInitial,
    setRangerPriceSaleFinal,
    setRangerPriceSaleInitial,
    setTypeBuildsSelected,
    handleSearchBuildingsAdvanced,
  } = useSearchAdvanced()

  useEffect(() => {
    if (user) {
      setModalLogin(false)
    }
  }, [user])

  useEffect(() => {
    if (scroll.y > 500) {
      setShow(true)
    } else {
      // setSearchMobile(false)
      setShow(false)
    }

    if (scroll.lastY < scroll.y) {
      setShow(true);
    } else {
      // setSearchMobile(false)
      setShow(false);
    }
  }, [scroll])

  const handleLogin = useCallback(async (credentialResponse: GoogleCredentialResponse) => {
    // °console.log(credentialResponse)
    // setCredentials(credentialResponse.credential)
    if (credentialResponse.credential) {
      const obj: IPropsGoogleLogin = await jwt_decode(credentialResponse.credential)
      // console.log(obj)
      signIn({ email: obj.email, objUser: obj })
    }
  }, [signIn])

  const handleSearchFunction = useCallback(() => {
    handleSearchBuildings()
    if (window.location.href !== `${process.env.REACT_APP_URL_PORTAL}/resultado-pesquisa`) {
      navigate(`/resultado-pesquisa`)
    }
    setSearchMobile(false)
  }, [handleSearchBuildings, navigate])

  const handleSearchAdvancedFunction = useCallback(() => {
    handleSearchBuildingsAdvanced()
    setModal2Open(!modal2Open)
    if (window.location.href !== `${process.env.REACT_APP_URL_PORTAL}/resultado-pesquisa`) {
      navigate(`/resultado-pesquisa`)
    }
    setSearchMobile(false)
  }, [handleSearchBuildingsAdvanced, navigate, modal2Open])


  function onSearch(e: any) {
    if (e.key === 'Enter') {
      handleSearchFunction()
    }
  }

  const handleChangeSaleInicial = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = Number(event.target.value.replace(/\D/g, '')) / 100;
    setRangerPriceSaleInitial(inputValue);
  };

  const handleChangeSaleFinal = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = Number(event.target.value.replace(/\D/g, '')) / 100;
    setRangerPriceSaleFinal(inputValue);
  };

  const handleChangeRentInicial = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = Number(event.target.value.replace(/\D/g, '')) / 100;
    setRangerPriceRentInitial(inputValue);
  };

  const handleChangeRentFinal = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = Number(event.target.value.replace(/\D/g, '')) / 100;
    setRangerPriceRentFinal(inputValue);
  };

  //funções do select do header

  const handleChangeOperations = (value: string[]) => {
    setOperationSelected(value)
  }

  const handleChangeCategorys = (value: string) => {
    setCategorySelected(value)
  }

  const handleChangeTypeBuilds = (value: string[]) => {
    setTypeBuildsSelected(value)
  }

  useEffect(() => {
    if (address) {
      geocodeByAddress(address.label).then(res => {
        if (res[0]?.address_components?.length === 7) {
          setDistrict(res[0]?.address_components[2]?.long_name)
          setCity(res[0]?.address_components[3]?.long_name)
          setState(res[0]?.address_components[4]?.short_name)
          return
        }
        if (res[0]?.address_components?.length === 6) {
          setDistrict(res[0]?.address_components[1]?.long_name)
          setCity(res[0]?.address_components[2]?.long_name)
          setState(res[0]?.address_components[3]?.short_name)
          return
        }
        if (res[0]?.address_components?.length === 4 && res[0]?.address_components[0]?.types.length === 3) {
          setDistrict(res[0]?.address_components[0]?.long_name)
          setCity(res[0]?.address_components[1]?.long_name)
          setState(res[0]?.address_components[2]?.short_name)
          return
        }
        if (res[0]?.address_components?.length === 4 && res[0]?.address_components[0]?.types.length === 2) {
          setDistrict('')
          setCity(res[0]?.address_components[1]?.long_name)
          setState(res[0]?.address_components[2]?.short_name)
          return
        }
        if (res[0]?.address_components?.length === 2) {
          setDistrict('')
          setCity('')
          setState(res[0]?.address_components[0]?.short_name)
          return
        }
        if (res[0]?.address_components?.length === 1) {
          setDistrict('')
          setCity('')
          setState('')
          return
        }
        setAddress([])
        // if (res[0].address_components.length === 7) {
        //     setCep(res[0].address_components[6]?.long_name)
        //     setStreet(res[0].address_components[1].long_name)
        //     setNumber(res[0].address_components[0]?.long_name)
        //     setDistrict(res[0].address_components[2].long_name)
        //     setCity(res[0].address_components[3].long_name)
        //     setState(res[0].address_components[4].short_name)
        //     setStreetEdit(res[0].address_components[1].long_name.split(" ").join("+"))
        //     setLat(res[0].geometry.viewport.Ab.h)
        //     setLng(res[0].geometry.viewport.Ra.h)
        // } else if (res[0].address_components.length === 6) {
        //     setStreet(res[0].address_components[0].long_name)
        //     setDistrict(res[0].address_components[1].long_name)
        //     setCity(res[0].address_components[2].long_name)
        //     setState(res[0].address_components[3].short_name)
        //     setStreetEdit(res[0].address_components[0].long_name.split(" ").join("+"))
        //     setLat(res[0].geometry.viewport.Ab.h)
        //     setLng(res[0].geometry.viewport.Ra.h)
        // }
      })
    }
  }, [address, setState, setCity, setDistrict])

  const handleAdvancedLancamento = useCallback((value: boolean) => {
    setAdvancedOperationLancamento(value)
    if (advancedOperationLacamento) {
      advancedSearchOperation.push('0')
      return
    }

    if (!advancedOperationLacamento) {
      let arr = []
      arr = advancedSearchOperation.filter(a => a !== '0')
      setAdvancedSearchOperation(arr)
      return
    }
  }, [advancedOperationLacamento, advancedSearchOperation, setAdvancedSearchOperation, setAdvancedOperationLancamento])

  const handleAdvancedVenda = useCallback((value: boolean) => {
    setAdvancedOperationVenda(value)
    if (advancedOperationVenda) {
      advancedSearchOperation.push('1')
      return
    }

    if (!advancedOperationVenda) {
      let arr = []
      arr = advancedSearchOperation.filter(a => a !== '1')
      setAdvancedSearchOperation(arr)
      return
    }
  }, [advancedOperationVenda, advancedSearchOperation, setAdvancedSearchOperation, setAdvancedOperationVenda])

  const handleAdvancedAluguel = useCallback((value: boolean) => {
    setAdvancedOperationAluguel(value)
    if (advancedOperationAluguel) {
      advancedSearchOperation.push('2')
      return
    }

    if (!advancedOperationAluguel) {
      let arr = []
      arr = advancedSearchOperation.filter(a => a !== '2')
      setAdvancedSearchOperation(arr)
      return
    }
  }, [advancedOperationAluguel, advancedSearchOperation, setAdvancedSearchOperation, setAdvancedOperationAluguel])


  // useEffect(()=>{console.log('advanced',advancedSearchOperation)},[advancedSearchOperation])

  // console.log('advanced',advancedSearchOperation)

  // const clearOptionsAdvanced = useCallback(() => {
  //   setAdvancedOperationAluguel(true)
  //   setAdvancedOperationLancamento(true)
  //   setAdvancedOperationVenda(true)
  //   setAdvancedSearchOperation([])
  //   setCardsCategoriesSelect([])
  //   setCity('')
  //   setDistrict('')
  //   setState('')
  //   setComodoBathrooms(0)
  //   setComodoBedRooms(0)
  //   setComodoSuites(0)
  //   setComodoVagas(0)
  //   setRangerPriceRentFinal(0)
  //   setRangerPriceRentInitial(0)
  //   setRangerPriceSaleFinal(0)
  //   setRangerPriceSaleInitial(0)
  //   setTypeBuildsSelected([])
  // }, [cardsCategoriesSelect, setCardsCategoriesSelect])

  const closeModal = useCallback(() => {
    setAdvancedOperationAluguel(true)
    setAdvancedOperationLancamento(true)
    setAdvancedOperationVenda(true)
    setAdvancedSearchOperation([])
    setCardsCategoriesSelect([])
    setCity('')
    setDistrict('')
    setState('')
    setComodoBathrooms(0)
    setComodoBedRooms(0)
    setComodoSuites(0)
    setComodoVagas(0)
    setRangerPriceRentFinal(0)
    setRangerPriceRentInitial(0)
    setRangerPriceSaleFinal(0)
    setRangerPriceSaleInitial(0)
    setTypeBuildsSelected([])
    setModal2Open(false)
  }, [])

  return (
    <Container className={show ? 'hidden' : ''}>
      <ContentWrapper>
        <div className='header-mobile'>
          <a className="log-mobile" >
            <img
              src={logoNew}
              alt="Nogueira"
            // onClick={() => navigate('/')}
            />
          </a>

          <button
            onClick={() => setSearchMobile(!searchMobile)}
          >
            Pesquisar
            {searchMobile ?
              <MdKeyboardArrowUp className='icon-button-search-mobile' />
              : <MdKeyboardArrowDown className='icon-button-search-mobile' />}
          </button>

          <div className="right-mobile">
            {
              user ?
                <div className='user-logged'>
                  {
                    person?.foto_perfil ?
                      <img src={person?.foto_perfil} alt="avatar" onClick={() => navigate('/perfil-interno')} className='avatar-logged-mobile' />
                      :
                      <FaUserCircle className='no-avatar-logged-mobile' />
                  }
                  {/* <p>Olá, <span>{person?.nome ? person?.nome?.split(' ')[0] : ''}</span></p> */}
                  <FiLogOut className='logout-mobile' onClick={() => setModalLogout(true)} />
                </div>
                :
                // <p className='text-login'>
                //   É um corretor Nogueira?
                //   <span onClick={() => setModalLogin(true)}>
                //     Clique aqui
                //     <IoLogInOutline className='icon-login' />
                //   </span>
                // </p>
                <div className='user-no-logged-mobile' onClick={() => setModalLogin(true)}>
                  <FaUserCircle className='avatar-no-logged-mobile' />
                  <p className='title-login-mobile'>LOGIN</p>
                </div>
            }
          </div>
        </div>


        <div className="header-wrapper">

          <a className="log" href='/'>
            <div className="log" >
              <img
                src={logoNew}
                alt="Nogueira"
                onClick={() => { setOperationSelected([]); setCategorySelected(''); setSearch(''); }}
              />
            </div>
          </a>

          <div className='left-mobile'>

            {
              searchMobile &&
              <div className='selects-mobile'>
                <div className='div-select-operation-mobile'>
                  <ConfigProvider
                    theme={{
                      components: {
                        Select: {
                          controlHeight: 5,
                          colorBgContainer: 'transparent',
                          colorBorder: 'transparent',
                          borderRadius: 0,
                          colorTextPlaceholder: '#888A88',
                        }
                      }
                    }}
                  >
                    <Select
                      // showArrow={true}
                      maxTagTextLength={1}
                      showSearch={false}
                      bordered={false}
                      style={{ width: '100%', backgroundColor: 'transparent' }}
                      mode='multiple'
                      allowClear
                      placeholder='Lançamentos'
                      options={options}
                      className='select-operation-mobile'
                      onChange={handleChangeOperations}
                    />
                  </ConfigProvider>
                </div>

                <div className='div-select-category-mobile'>
                  <ConfigProvider
                    theme={{
                      components: {
                        Select: {
                          colorTextPlaceholder: '#888A88'
                        }
                      }
                    }}
                  >
                    <Select
                      // showArrow={true}
                      showSearch={false}
                      bordered={false}
                      style={{ width: '100%', backgroundColor: 'transparent' }}
                      allowClear
                      placeholder='Categorias'
                      options={categorys?.map(cat => { return { value: cat.id, label: cat.titulo } })}
                      className='select-category-mobile'
                      onChange={handleChangeCategorys}
                    />
                  </ConfigProvider>
                </div>
                <input type="text" className='input-search-mobile' value={search} onKeyDown={(e) => { onSearch(e) }} onChange={(e) => setSearch(e.target.value)} placeholder='Procurar por...' />
                <div className='content-div-buttons'>
                  <button className='search-button' onClick={handleSearchFunction}>Pesquisar<BiSearch className='icon-search-button' /></button>
                  <button className='advanced-button' onClick={() => setModal2Open(!modal2Open)}>Abrir pesquisa avançada<BiSliderAlt className='icon-advanced-button' /></button>
                </div>
              </div>
            }
          </div>

          <div className="left">

            <div className='div-select-operation'>
              <ConfigProvider
                theme={{
                  components: {
                    Select: {
                      controlHeight: 5,
                      colorBgContainer: 'transparent',
                      colorBorder: 'transparent',
                      borderRadius: 0,
                      colorTextPlaceholder: '#888A88'
                    }
                  }
                }}
              >
                <Select maxTagTextLength={1} showSearch={false} bordered={false} style={{ width: '220px', backgroundColor: 'transparent' }} mode='multiple' allowClear placeholder='Lançamentos' options={options} className='select-operation' onChange={handleChangeOperations} />
              </ConfigProvider>
            </div>

            <div className='div-select-category'>
              <ConfigProvider
                theme={{
                  components: {
                    Select: {
                      colorTextPlaceholder: '#888A88'
                    }
                  }
                }}
              >
                <Select allowClear bordered={false} options={categorys?.map(cat => { return { value: cat.id, label: cat.titulo } })} showSearch={false} className='select-category' placeholder='Categorias' onChange={handleChangeCategorys} />
              </ConfigProvider>

            </div>

            <input value={search} onKeyDown={(e) => { onSearch(e) }} onChange={(e) => setSearch(e.target.value)} type="text" placeholder='Procurar por imóveis' />

            {
              ((search !== undefined && search?.length > 0) || (categorySelected !== '' && categorySelected?.length > 0) || (operationSelected?.length !== 0 && operationSelected?.length > 0)) &&
              <IoClose
                size={18}
                className='icon-erase-text'
                onClick={() => {
                  setSearch('');
                  setCategorySelected('');
                  setOperationSelected([]);
                }}
              />
            }

            <BiSearch
              size={22}
              className='icon'
              onClick={handleSearchFunction}
            />

            <div className="btn-filter" onClick={() => setModal2Open(!modal2Open)}>
              <BiSliderAlt />
              <h4>Filtros</h4>
            </div>
          </div>

          <div className="right">
            {
              user ?
                <div className='user-logged'>
                  {
                    person?.foto_perfil ?
                      <img className='img-logged' onClick={() => navigate('/perfil-interno')} src={person?.foto_perfil} alt="" />
                      :
                      <FaUserCircle className='avatar-logged' onClick={() => navigate('/perfil-interno')} />
                  }
                  <p>Olá, <span onClick={() => navigate('/perfil-interno')}>{person?.nome ? person?.nome?.split(' ')[0] : ''}</span></p>
                  <FiLogOut className='logout' onClick={() => setModalLogout(true)} />
                </div>
                :
                <div className='user-no-logged' onClick={() => setModalLogin(true)}>
                  <FaUserCircle className='avatar-no-logged' />
                  <p className='title-login'>LOGIN</p>
                </div>
            }
          </div>
        </div>
      </ContentWrapper>

      <Modal
        title="Filtros"
        centered
        width={700}

        style={{ maxHeight: '80vh' }}
        // okText={"Filtrar"}
        // okType='primary'
        // cancelText={'Cancelar'}
        open={modal2Open}
        // onOk={() => setModal2Open(false)}
        onCancel={closeModal}
        footer={null}
      >
        
        <AdvancedSearch show />

        <ContentButtonsModalFilter>
          <button className='button-cancel' onClick={() => setModal2Open(false)}>Cancelar</button>
          <button className='button-accept' onClick={handleSearchAdvancedFunction}>Pesquisar</button>
        </ContentButtonsModalFilter>
      </Modal>


      <Modal
        title='LOGIN'
        centered
        width={380}
        open={modalLogin}
        onOk={() => setModalLogin(false)}
        onCancel={() => setModalLogin(false)}
        footer={null}

      >
        <ContentModalGoogle>
          <img src={logoNew} style={{ textAlign: 'center' }} alt="Logo Hub Nogueira" />
          {
            loading &&
            <LoadSpinnerLogin />
          }
          {
            !loading &&
            <>
              <div className='form-group'>
                <label htmlFor="">E-mail:</label>
                <input className='input-login' value={email} onChange={(e) => setEmail(e.target.value)} type="email" placeholder='e-mail' autoComplete='on' />
              </div>

              <div className='form-group'>
                <label htmlFor="">Senha:</label>
                <div className='input-login-div'>
                  <input className='input-div-login' value={password} onChange={(e) => setPassword(e.target.value)} type={viewPassword ? 'text' : 'password'} placeholder='senha' />
                  <button className='button-eye' onClick={() => setViewPassword(!viewPassword)}>{viewPassword ? <FaRegEye /> : <FaRegEyeSlash />}</button>
                </div>
              </div>

              <br />

              <button onClick={handleLoginWithEmailAndPassword} className='button-login'>Entrar</button>

              <div className='other_option' style={{ marginTop: -2, width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                <span><a style={{ fontSize: '12px' }} href="/esqueci-minha-senha" target='_blank'>Esqueci minha senha</a></span>
                <span><a style={{ fontSize: '12px' }} href="/primeiro-acesso" target='_blank'>Sou novo aqui</a></span>
              </div>

              {/* <GoogleLogin
                onSuccess={(credentialResponse) => handleLogin(credentialResponse)}
                onError={() => {
                  console.log('Login Failed');
                }}
              /> */}
            </>
          }

        </ContentModalGoogle>
      </Modal>

      <Modal
        // title='LOGOUT'
        centered
        width={300}
        open={modalLogout}
        onOk={() => setModalLogout(false)}
        onCancel={() => setModalLogout(false)}
        footer={null}

      >
        <ContentModalGoogle>
          <img src={logoNew} alt="Logo Hub Nogueira" />
          {
            loading &&
            <LoadSpinnerLogin />
          }
          {
            !loading &&
            <>
              <p className='title-logout'>Tem certeza que deseja sair?</p>
              <div className='content-button'>
                <button onClick={() => { signOut(); setModalLogout(false) }} >Sair</button>
              </div>
            </>
          }

        </ContentModalGoogle>
      </Modal>
      <GoToTop />
    </Container>
  );
}