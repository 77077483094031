import { useCallback, useEffect, useState } from "react";
import { Header, ScrollProps } from "../../components/Header";
import { Container } from "./styles";
import React from 'react';
import { GoToTop } from "../../GoToTop";
import { Footer } from "../../components/Footer";
import { useAuth } from "../../context/auth";
import { Modal } from "antd";
import { ModalContent } from "./ModalContent";
import { format } from "date-fns";
// import { tasks as taskList, employees } from './data';


interface IKanbanItem {
    id: number;
    status: number,
    cliente: string,
    construtora: string,
    descricao_imovel: string,
    bloco: string,
    uuid: string,
    created_at: Date,
    updated_at: Date,
    valor: number,
}


export function Kanban() {

    const { user, api } = useAuth()
    const [scrollTop, setScrollTop] = useState<ScrollProps>({ y: 0, lastY: 0 } as ScrollProps);
    const [show, setShow] = useState(false);
    const [search, setSearch] = useState('');
    const [kanbanData, setKanbanData] = useState([] as IKanbanItem[]);
    const [totalPendente, setTotalPendente] = useState(0);
    const [totalPendenteValor, setTotalPendenteValor] = useState(0);
    const [totalEnviados, setTotalEnviados] = useState(0);
    const [totalEnviadosValor, setTotalEnviadosValor] = useState(0);
    const [totalVistas, setTotalVistas] = useState(0);
    const [totalVistasValor, setTotalVistasValor] = useState(0);
    const [totalAprovadas, setTotalAprovadas] = useState(0);
    const [totalAprovadasValor, setTotalAprovadasValor] = useState(0);
    const [totalRejeitadas, setTotalRejeitadas] = useState(0);
    const [totalRejeitadasValor, setTotalRejeitadasValor] = useState(0);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedIdContract, setSelectedIdContract] = useState(0);
    const [loading, SetLoading] = useState(true);


    let statusKanban = [
        { 'statusCode': -1, 'descricao': 'Pendentes', 'color': '#a1a1a1', 'total': 0 },
        { 'statusCode': 0, 'descricao': 'Enviadas', 'color': '#635b8f', 'total': 0 },
        { 'statusCode': 1, 'descricao': 'Vistas', 'color': '#5d99cc', 'total': 0 },
        { 'statusCode': 2, 'descricao': 'Aprovadas', 'color': '#68cc5d', 'total': 0 },
        { 'statusCode': 3, 'descricao': 'Rejeitadas', 'color': '#ca5757', 'total': 0 }
    ];


    const loadKanbanData = useCallback(async () => {
        await api.get(`/sin/proposta/${user.id_corretor}`).then(res => {
            setKanbanData(res.data);
            // console.log(res.data);
            let tempPendente = 0;
            let tempPendenteValor = 0;
            let tempEnviados = 0;
            let tempEnviadosValor = 0;
            let tempVistas = 0;
            let tempVistasValor = 0;
            let tempAprovadas = 0;
            let tempAprovadasValor = 0;
            let tempRejeitadas = 0;
            let tempRejeitadasValor = 0;

            (res.data as IKanbanItem[]).forEach((v) => {
                if (v.status === -1) {
                    tempPendente++;
                    tempPendenteValor += Number(v.valor);
                } else if (v.status === 0) {
                    tempEnviados++;
                    tempEnviadosValor += Number(v.valor);
                } else if (v.status === 1) {
                    tempVistas++;
                    tempVistasValor += Number(v.valor);
                } else if (v.status === 2) {
                    tempAprovadas++;
                    tempAprovadasValor += Number(v.valor);
                } else if (v.status === 3) {
                    tempRejeitadas++;
                    tempRejeitadasValor += Number(v.valor);
                }
            });

            setTotalPendente(tempPendente);
            setTotalPendenteValor(tempPendenteValor);
            setTotalEnviados(tempEnviados);
            setTotalEnviadosValor(tempEnviadosValor);
            setTotalVistas(tempVistas);
            setTotalVistasValor(tempVistasValor);
            setTotalAprovadas(tempAprovadas);
            setTotalAprovadasValor(tempAprovadasValor);
            setTotalRejeitadas(tempRejeitadas);
            setTotalRejeitadasValor(tempRejeitadasValor);
            SetLoading(false);

        }).catch(e => console.log(e))
    }, [user.id_corretor, api])


    const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
        const scrollTop = event.currentTarget.scrollTop;
        setScrollTop(prevState => { return { y: scrollTop, lastY: prevState.y } })
    }


    useEffect(() => {
        loadKanbanData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <>
            <Modal
                title='Detalhes da Proposta'
                centered
                width={1000}
                open={modalIsOpen}
                onOk={() => { }}
                onCancel={() => { setModalIsOpen(false) }}
                footer={null}
            >
                <ModalContent id_proposta={selectedIdContract} />
            </Modal>

            <Container onScroll={handleScroll}>
                <Header scroll={scrollTop} show={show} setShow={setShow} search={search} setSearch={setSearch} />

                <div className="content_wrapper">
                    <h2 className="page_title" >Propostas</h2>

                    <div id="kanban_wrapper">
                        <div id='div_kanban'>
                            {statusKanban.map((value, index) => {
                                return (
                                    <div className="div_kanban_collum" key={index}>
                                        <h3 style={{ background: `${value.color}` }}>
                                            <span style={{ fontSize: '16px' }}>
                                                {!loading && <span style={{ fontSize: '16px' }}>{value.statusCode === -1 ? totalPendente : (value.statusCode === 0 ? totalEnviados : (value.statusCode === 1 ? totalVistas : (value.statusCode === 2 ? totalAprovadas : totalRejeitadas)))}</span>}
                                                &nbsp;{value.descricao}
                                            </span>
                                            <div>
                                                {!loading && <span style={{ fontSize: '16px' }}>{value.statusCode === -1 ? totalPendenteValor.toLocaleString('pt-br', { minimumFractionDigits: 2 }) : (value.statusCode === 0 ? totalEnviadosValor.toLocaleString('pt-br', { minimumFractionDigits: 2 }) : (value.statusCode === 1 ? totalVistasValor.toLocaleString('pt-br', { minimumFractionDigits: 2 }) : (value.statusCode === 2 ? totalAprovadasValor.toLocaleString('pt-br', { minimumFractionDigits: 2 }) : totalRejeitadasValor.toLocaleString('pt-br', { minimumFractionDigits: 2 }))))}</span>}
                                            </div>
                                        </h3>
                                        
                                        <ul className="ul_kanban_collum ">
                                            {loading ?
                                                <div className="loading description">
                                                </div>
                                                :
                                                kanbanData.map((val, i) => {
                                                    if (val.status === value.statusCode) {
                                                        return (
                                                            <li className="card" key={i} onClick={() => { setSelectedIdContract(val.id); setModalIsOpen(true) }}>
                                                                <div className="basel" style={{ background: `${value.color}` }}></div>
                                                                <div className="li_content">
                                                                    <h6>{val.cliente}</h6>
                                                                    <div className="building_description" >{val.descricao_imovel}</div>
                                                                    <div className="" style={{ marginTop: 4, fontSize: 12, color: `${value.color}` }} >{val.construtora}</div>
                                                                    <div className="value_wrapper" >
                                                                        {/* <span style={{fontSize:13, marginTop:2, opacity:.6}} >{`${(new Date(val.updated_at)).getDate() < 10 ? '0': ''}${(new Date(val.updated_at)).getDate()}/${(new Date(val.updated_at)).getMonth() < 10 ? '0' : ''}${(new Date(val.updated_at)).getMonth()}/${(new Date(val.updated_at)).getFullYear()}`}</span> */}
                                                                        <span style={{fontSize:13, marginTop:2, opacity:.6}} >{format(new Date(val.updated_at), 'dd/MM/yyyy')}</span>
                                                                        <span style={{fontSize:16}} >R$ {Number(val.valor).toLocaleString('pt-br', { minimumFractionDigits: 2 })}</span>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        );
                                                    } else {
                                                        return '';
                                                    }
                                                })}

                                        </ul>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>

                <GoToTop />
                <Footer />
            </Container>
        </>
    )
}
