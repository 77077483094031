import { useCallback, useState, useEffect } from 'react';
import { BiArea, BiBuildingHouse, BiImages } from 'react-icons/bi';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '../../components/Button';
import { Header, ScrollProps } from '../../components/Header';
import { ShowAllPhotos } from '../../components/ShowAllPhotos';
import { motion } from 'framer-motion';
import { IBuildindCorretor, IPropertyDetailsCorrector } from '../../types';
import {
  Container,
} from './styles';
import { Footer } from '../../components/Footer';
import { LoadSpinner2 } from '../../components/LoadSpinner2';
import { ShowPhotoLargue } from '../../components/ShowPhotoLarge';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { maskPhone } from '../../utils/mask';
import { toast } from 'react-toastify';
import validator from 'validator';
import { MdLocalPhone, MdMeetingRoom, MdOutlineBathroom } from 'react-icons/md';
import { FiMinusCircle, FiPlusCircle, FiShare } from 'react-icons/fi';
import { GoogleMap, MarkerF, useJsApiLoader } from '@react-google-maps/api';
import { useAuth } from '../../context/auth';
import { BsStar, BsStarFill, BsWhatsapp } from 'react-icons/bs';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { GoToTop } from '../../GoToTop';
import { FaChartArea, FaRegCalendarAlt } from 'react-icons/fa';
import { CardInfo } from '../../components/CardInfo';
import { Carousel as Carosel2 } from 'react-responsive-carousel';
import Carousel from 'react-multi-carousel';


const MySwal = withReactContent(Swal)


export function PropertyDetails() {

  const navigate = useNavigate()

  const { id } = useParams();
  const { user, api } = useAuth()

  const [loading, setLoading] = useState(false)
  const [loadingRelations, setLoadingRelations] = useState(false)
  const [loadingContact, setLoadingContact] = useState(false)
  const [search, setSearch] = useState('');
  const [lat, setLat] = useState<number>(0);
  const [long, setLong] = useState<number>(0);

  const [obs, setObs] = useState('');
  const [nomeCliente, setNomeCliente] = useState('');
  const [emailCliente, setEmailCliente] = useState('');
  const [contatoCliente1, setContatoCliente1] = useState('');
  const [horaReserva, setHoraReserva] = useState('')

  const [dateReserve, setDateReserve] = useState<any>()
  const [dateFormat, setDateFormat] = useState<any>()

  const [viewAllPhotos, setViewAllPhotos] = useState(false);
  const [viewPhotoFull, setViewPhotoFull] = useState(false);
  const [index, setIndex] = useState<number>();

  const [add, setAdd] = useState(false)
  const [destaque, setDestaque] = useState(false)
  const [viewDestaque, setViewDestaque] = useState(false)

  const [scrollTop, setScrollTop] = useState<ScrollProps>({ y: 0, lastY: 0 } as ScrollProps);
  const [show, setShow] = useState(false);
  const [isTime, setIsTime] = useState(false);


  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: 'AIzaSyCDsc9pmM4xfjWcIyNokhgbrd5SYQDiz7o'
  })

  const openOrCloseViewPhotos = () => {
    setViewAllPhotos(!viewAllPhotos);
  }

  const openOrCloseViewPhotosFull = () => {
    setViewPhotoFull(!viewPhotoFull);
  }

  const [dataProperty, setDataProperty] = useState<IPropertyDetailsCorrector>({} as IPropertyDetailsCorrector);
  const [dataRelacionados, setDataRelacionados] = useState<IBuildindCorretor[]>([])

  const loadData = useCallback(() => {
    if (user) {
      setLoading(true)
      api.get(`/portal/building/corret/${id}?id_corretor=${user?.id_corretor}`)
        .then((res) => {
          setDataProperty(res.data[0]);
          console.log('res', res.data[0]);
        })
        .catch(e => console.log(e.response.data.message))
        .finally(() => setLoading(false));
    } else {
      setLoading(true)
      api.get(`portal/building/${id}`)
        .then((res) => {
          setDataProperty(res.data[0]);
          console.log('res', res.data[0]);
        })
        .catch(e => console.log(e.response.data.message))
        .finally(() => setLoading(false));
    }
  }, [api, id, user]);

  const loadRelacionados = useCallback(() => {
    setLoadingRelations(true)
    if (user) {
      api.get(`/portal/building/corret-list-building/0?query_search&is_sale=${dataProperty.is_venda}&min_sale_price=${dataProperty.valor_venda - (dataProperty.valor_venda * (20 / (100)))}&max_sale_price=${dataProperty.valor_venda + (dataProperty.valor_venda * (20 / (100)))}&is_rent=${dataProperty.is_aluguel}&min_rent_price=${dataProperty.valor_aluguel - (dataProperty.valor_aluguel * (20 / (100)))}&max_rent_price=${dataProperty.valor_aluguel + (dataProperty.valor_aluguel * (20 / (100)))}&seller&constructor&no_address&id_category&is_highlight&id_corretor=${user?.id_corretor}&is_launch`).then(res => {
        setDataRelacionados(res.data)
      }).catch(e => console.log(e)).finally(() => setLoadingRelations(false))
    } else {
      api.get(`/portal/building/list-building/0?query_search&is_sale=${dataProperty.is_venda}&min_sale_price=${dataProperty.valor_venda - (dataProperty.valor_venda * (20 / (100)))}&max_sale_price=${dataProperty.valor_venda + (dataProperty.valor_venda * (20 / (100)))}&is_rent=${dataProperty.is_aluguel}&min_rent_price=${dataProperty.valor_aluguel - (dataProperty.valor_aluguel * (20 / (100)))}&max_rent_price=${dataProperty.valor_aluguel + (dataProperty.valor_aluguel * (20 / (100)))}&seller&constructor&no_address=1&id_category&is_highlight&id_constructor&is_lanch`).then(res => {
        setDataRelacionados(res.data)
      }).catch(e => console.log(e)).finally(() => setLoadingRelations(false))
    }
  }, [user, api, dataProperty.is_venda, dataProperty.valor_venda, dataProperty.is_aluguel, dataProperty.valor_aluguel])

  useEffect(() => {
    loadData();
    loadRelacionados();
  }, [loadData, loadRelacionados])

  const handleVisita = useCallback(async () => {

    if (nomeCliente === '') {
      return toast.error('Digite o seu nome', {
        position: "top-right",
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored'
      })
    }

    if (contatoCliente1 === '') {
      return toast.error('Digite o número para contato', {
        position: "top-right",
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored'
      })
    }

    if (emailCliente === '') {
      return toast.error('Informe um e-mail para contato', {
        position: "top-right",
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored'
      })
    }

    if (!validator.isEmail(emailCliente)) {
      return toast.error('Digite um e-mail válido', {
        position: "top-right",
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored'
      })
    }

    if (dateReserve === undefined) {
      return toast.error('Selecione uma data para visita', {
        position: "top-right",
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored'
      })
    }

    if (isTime && horaReserva === '') {
      return toast.error('Selecione um horário para visita', {
        position: "top-right",
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored'
      })
    }

    setLoadingContact(true)
    MySwal.fire({
      title: 'Aguarde',
      text: 'Estamos salvando as informações',
      icon: 'warning',
      didOpen: () => {
        MySwal.showLoading()
      }
    })
    await api.post('/schedule/add-visita', {
      dt_inicio: horaReserva !== '' ? dateFormat : new Date(dateReserve).toISOString(),
      descricao: 'Agendamento de visita',
      observacao: obs,
      id_imovel: dataProperty.id,
      cliente_descricao: nomeCliente,
      cliente_email: emailCliente,
      cliente_telefone1: contatoCliente1,
      cliente_telefone2: contatoCliente1,
    }).then(res => {
      setObs('')
      setNomeCliente('')
      setEmailCliente('')
      setContatoCliente1('')
      setIsTime(false)
      setHoraReserva('');
      setDateReserve(new Date())
      MySwal.fire({
        icon: 'success',
        title: 'Sucesso!',
        text: 'O processo foi realizado com sucesso. Em breve a corretor retornará o seu contato.',
        confirmButtonColor: '#0E2244'
      })
    }).catch(e => {
      console.log(e)
      MySwal.fire({
        icon: 'error',
        title: 'Ops!',
        text: 'Não foi possível agendar sua visita.',
        confirmButtonColor: '#0E2244',
        footer: `${e.response.data.err}`
      })
    }).finally(() => setLoadingContact(false))
  }, [nomeCliente, contatoCliente1, emailCliente, dateReserve, isTime, horaReserva, api, dateFormat, obs, dataProperty.id])


  useEffect(() => {
    navigator.geolocation.watchPosition(position => {
      const { latitude, longitude } = position.coords;
      setLat(latitude ? latitude : 0);
      setLong(longitude ? longitude : 0);
    });
  }, [])

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const scrollTop = event.currentTarget.scrollTop;
    setScrollTop(prevState => { return { y: scrollTop, lastY: prevState.y } })
  }

  const handleAddToProfile = useCallback(async (idBuilding: number) => {
    await api.post('/portal/seller/add-to-profile', {
      id_corretor: user?.id_corretor,
      id_imovel: idBuilding
    }).then(res => {
      setAdd(true)
      setViewDestaque(true)
      return toast.success('Imóvel adicionado com sucesso ao seu perfil', {
        position: "top-right",
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored'
      })
    }).catch(e => {
      console.log(e)
      return toast.error('Não foi possível adicionar o imóvel ao seu perfil', {
        position: "top-right",
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored'
      })
    })
  }, [api, user])

  const handleRemoveToProfile = useCallback(async (idBuilding: number) => {
    await api.post('/portal/seller/remove-from-profile', {
      id_corretor: user?.id_corretor,
      id_imovel: idBuilding
    }).then(res => {
      setAdd(false)
      setViewDestaque(false)
      setDestaque(false)
      return toast.success('Imóvel retirado com sucesso do seu perfil', {
        position: "top-right",
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored'
      })
    }).catch(e => {
      console.log(e)
      return toast.error('Não foi possível retirar o imóvel do seu perfil', {
        position: "top-right",
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored'
      })
    })
  }, [api, user])

  const handleAddDestaque = useCallback(async (idBuilding: number) => {
    await api.post('/portal/seller/highlight', {
      id_corretor: user?.id_corretor,
      id_imovel: idBuilding
    }).then(res => {
      setDestaque(true)
      return toast.success('Imóvel adicionado com sucesso aos seus destaques', {
        position: "top-right",
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored'
      })
    }).catch(e => {
      console.log(e)
      toast.error('Não foi possível adicionar o imóvel aos destaques', {
        position: "top-right",
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored'
      })
      toast.error('Adicione o imóvel ao seu perfil primeiro', {
        position: "top-right",
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored'
      })
    })
  }, [api, user])

  const handleRemoveDestaque = useCallback(async (idBuilding: number) => {
    await api.post('/portal/seller/remove-highlight', {
      id_corretor: user?.id_corretor,
      id_imovel: idBuilding
    }).then(res => {
      setDestaque(false)
      return toast.success('Imóvel retirado com sucesso dos seus destaques', {
        position: "top-right",
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored'
      })
    }).catch(e => {
      console.log(e)
      return toast.error('Não foi possível retirar o imóvel dos destaques', {
        position: "top-right",
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored'
      })
    })
  }, [api, user])

  const copyToClipboard = (text: string) => {
    console.log(text)
    if (!navigator.clipboard) {
      return;
    }
    return navigator.clipboard.writeText(text).then(() => {
      toast.success('Link da página copiado com sucesso', {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored'
      })
    }).catch(() => {
      toast.error('Não foi possível copiar o link da página', {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored'
      })
      console.log('Não foi possível copiar o link da página')
    })
  }

  useEffect(() => {
    let hora = ''
    if (dateReserve !== undefined) {
      hora = new Date(dateReserve).toISOString()
    }
    setDateFormat(`${hora.split('T')[0]}:00.000Z`)
  }, [horaReserva, dateReserve])

  function handleOpenPhotoFull(indx: number) {
    setViewPhotoFull(true)
    setIndex(indx)
  }

  return (
    <Container onScroll={handleScroll} show={show}>
      <Header show={show} setShow={setShow} scroll={scrollTop} search={search} setSearch={setSearch} />
      {
        (loading && loadingRelations) &&
        <LoadSpinner2 />
      }
      {
        (!loading && !loadingRelations) &&
        <>
          <div className="wrapper">
            <h1>{dataProperty?.descricao}</h1>
            <div className='sub-infos'>
              <a className='location' href={`https://maps.google.com/?q=${Number(dataProperty?.endereco?.latitude)},${Number(dataProperty?.endereco?.longitude)}`} target='_blank' rel="noreferrer" >{`${dataProperty?.endereco?.logradouro} ${dataProperty?.endereco?.numero}, ${dataProperty?.endereco?.bairro}, ${dataProperty?.endereco?.cidade} / ${dataProperty?.endereco?.estado}`}</a>
              <div className='share-favorite'>
                {
                  (user && (dataProperty.is_fixado === '1' || viewDestaque)) &&
                  <>
                    {
                      (dataProperty.is_destaque === 1 || destaque) ?
                        <BsStarFill className='add-destaque' onClick={() => handleRemoveDestaque(dataProperty.id)} />
                        :
                        ((dataProperty.is_destaque == null || !destaque) ?
                          <BsStar className='add-destaque' onClick={() => handleAddDestaque(dataProperty.id)} />
                          :
                          <></>
                        )
                    }
                  </>
                }
                {
                  user &&
                  <>
                    {
                      (dataProperty.is_fixado === '1' || add) ?
                        <FiMinusCircle className='icon-add' onClick={() => handleRemoveToProfile(dataProperty.id)} />
                        :
                        ((dataProperty.is_fixado === null || !add) ?
                          <FiPlusCircle className={'icon-add'} onClick={() => handleAddToProfile(dataProperty.id)} />
                          :
                          <></>
                        )
                    }
                  </>
                }
              </div>
            </div>

            <section className="box-images">
              <div className="left">
                {dataProperty?.arquivos !== undefined
                  && <img className='image-select' src={(dataProperty?.arquivos[0]?.url !== undefined && dataProperty?.arquivos[0]?.url !== 'https://www.nogueiracorretores.com.br/') ? dataProperty?.arquivos[0]?.url : 'https://portal-nogueira.s3.us-east-2.amazonaws.com/26848b2b91cbd520d366-placeholder-image-nogueira.png'} alt="Imagem Destaque Imóvel Nogueira" onClick={() => { setViewPhotoFull(true); setIndex(0) }} />
                  // : <img style={{opacity: .4}} src={'https://portal-nogueira.s3.us-east-2.amazonaws.com/26848b2b91cbd520d366-placeholder-image-nogueira.png'} alt="Imagem Imóvel Nogueira" />
                }
              </div>
              <div className="right">
                {
                  dataProperty?.arquivos !== undefined &&
                  <>
                    <div className='bg-color1'>
                      {
                        dataProperty?.arquivos[1]?.url !== undefined ?

                          <img src={dataProperty.arquivos[1].url} alt="Imagem Imóvel Nogueira" onClick={() => { setViewPhotoFull(true); setIndex(1) }} />
                          :
                          <img style={{opacity: .4}} src={'https://portal-nogueira.s3.us-east-2.amazonaws.com/26848b2b91cbd520d366-placeholder-image-nogueira.png'} alt="Imagem Imóvel Nogueira" />
                      }
                    </div>
                    <div className='bg-color2'>
                      {
                        (dataProperty?.arquivos[2]?.url !== undefined) ?
                          <img src={dataProperty?.arquivos[2]?.url} alt="Imagem Imóvel Nogueira" onClick={() => { setViewPhotoFull(true); setIndex(2) }} />
                          :
                          <img style={{opacity: .4}} src={'https://portal-nogueira.s3.us-east-2.amazonaws.com/26848b2b91cbd520d366-placeholder-image-nogueira.png'} alt="Imagem Imóvel Nogueira" />
                      }
                    </div>
                    <div className='bg-color3'>
                      {
                        (dataProperty?.arquivos[3]?.url !== undefined) ?
                          <img src={dataProperty?.arquivos[3]?.url} alt="Imagem Imóvel Nogueira" onClick={() => { setViewPhotoFull(true); setIndex(3) }} />
                          :
                          <img style={{opacity: .4}} src={'https://portal-nogueira.s3.us-east-2.amazonaws.com/26848b2b91cbd520d366-placeholder-image-nogueira.png'} alt="Imagem Imóvel Nogueira" />
                      }
                    </div>
                    <div className='bg-color4'>
                      {
                        (dataProperty?.arquivos[4]?.url !== undefined) ?
                          <img src={dataProperty?.arquivos[4]?.url} alt="Imagem Imóvel Nogueira" onClick={() => { setViewPhotoFull(true); setIndex(4) }} />
                          :
                          <img style={{opacity: .4}} src={'https://portal-nogueira.s3.us-east-2.amazonaws.com/26848b2b91cbd520d366-placeholder-image-nogueira.png'} alt="Imagem Imóvel Nogueira" />
                      }
                    </div>
                  </>
                }
              </div>
              <div className='view-images-mobile'>
                <Carosel2
                  autoPlay={true}
                  interval={4000}
                  showThumbs={false}
                  infiniteLoop={true}
                  showStatus={false}
                  onClickItem={(index: number) => handleOpenPhotoFull(index)}
                >
                  {
                    dataProperty.arquivos && dataProperty.arquivos.map(arquive => (
                      <img key={arquive.index} src={arquive.url} alt="imagem do imóvel" />
                    ))
                  }
                </Carosel2>
              </div>

              {
                dataProperty?.arquivos?.length > 0 &&
                <div className='more-images' onClick={openOrCloseViewPhotos}>
                  <BiImages size={22} />
                  <p>Mais fotos</p>
                </div>
              }

              {viewAllPhotos &&
                <motion.div
                  initial={{ opacity: .2, zIndex: 2000000000000000000000000000 }}
                  animate={{ opacity: 1 }}
                  transition={{
                    type: "spring",
                    damping: 15,
                    mass: .2,
                    stiffness: 150,
                    duration: 2,
                  }}
                  className='motion-div'
                >
                  <ShowAllPhotos
                    isOpen={viewAllPhotos}
                    files={dataProperty.arquivos}
                    onClose={openOrCloseViewPhotos}
                  />
                </motion.div>
              }
              {
                viewPhotoFull &&
                <motion.div
                  initial={{ opacity: .2, zIndex: 2000000000000000000000000000 }}
                  animate={{ opacity: 1 }}
                  transition={{
                    type: "spring",
                    damping: 15,
                    mass: .2,
                    stiffness: 150,
                    duration: 2,
                  }}
                  className='motion-div'
                >
                  <ShowPhotoLargue
                    isOpen={viewPhotoFull}
                    files={dataProperty.arquivos}
                    onClose={openOrCloseViewPhotosFull}
                    index={index}
                  />
                </motion.div>
              }
            </section>

            <div className='icons-share'>
              <div className='icon-and-text' onClick={() => copyToClipboard(window.location.href)} >
                <FiShare className='icon' />
                <p>Copiar link da página para compartilhar</p>
              </div>
              <a className='icon-and-text' href={`https://api.whatsapp.com/send?text=Vi este imóvel no Portal Hub Nogueira e acho que você pode gostar! \n https://portal.hubnogueira.com.br/imovel/${dataProperty.id}`} target='_blank' rel="noreferrer">
                <BsWhatsapp className='icon' />
                <p>Compartilhar no whatsapp</p>
              </a>
            </div>

            <div className='price-mobile'>
              <div className='value-property'>
                <p className='sub'>Valor à vista:</p>
                <p className='value'>{new Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL'
                }).format(dataProperty.valor_venda ? dataProperty.valor_venda : 0)}</p>
                {/* <div className='traco' /> */}
              </div>
            </div>

            <section className="informations">
              <div className="desc-left">

                <div className="box-desc">
                  <h3 style={{marginBottom: '12px'}}>Descrição </h3>

                  <div className="about-imovel">
                    <p style={{marginBottom: '0'}}>
                      {dataProperty.sobre}
                    </p>

                  </div>
                </div>

                <div className="overview" style={{marginBottom: '0'}}>
                  <h3 style={{marginBottom: '12px'}}>Visão Geral</h3>

                  <div className="items">

                    <div className="item-single">
                      <div className="icon"><MdMeetingRoom /> </div>
                      <div className="item-info">
                        <p>QUARTOS</p>
                        <p>{dataProperty?.qtd_quartos}</p>
                      </div>
                    </div>

                    <div className="item-single">
                      <div className="icon"><BiBuildingHouse /></div>
                      <div className="item-info">
                        <p>TIPO</p>
                        <p>{dataProperty?.id_tipo_imovel}</p>
                      </div>
                    </div>

                    <div className="item-single">
                      <div className="icon"><BiArea /></div>
                      <div className="item-info">
                        <p>ÁREA TOTAL</p>
                        <p>{dataProperty?.area_total}m²</p>
                      </div>
                    </div>

                    <div className="item-single">
                      <div className="icon"><MdOutlineBathroom /></div>
                      <div className="item-info">
                        <p>BANHEIROS</p>
                        <p>{dataProperty?.qtd_banheiros}</p>
                      </div>
                    </div>

                    <div className="item-single">
                      <div className="icon"><FaChartArea /></div>
                      <div className="item-info">
                        <p>ÁREA CONSTRUÍDA</p>
                        <p>{dataProperty?.area_construida}m²</p>
                      </div>
                    </div>

                    <div className="item-single">
                      <div className="icon"><FaRegCalendarAlt /></div>
                      <div className="item-info">
                        <p>ANO DE CONSTRUÇÃO</p>
                        <p>{dataProperty?.ano_construcao}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="resources" style={{marginBottom: '0'}}>
                  <h3 style={{marginBottom: '12px'}}>Características</h3>

                  <div className="resources-wrapper">
                    {
                      dataProperty?.convenience_relation?.length > 0 ?
                        dataProperty.convenience_relation.map(c => (
                          <div className="item"><img alt='' src={c?.url_icon} />{c?.titulo}</div>
                        ))
                        :
                        <div className='item'>Sem informações</div>
                    }
                  </div>
                </div>

                <div className="location">
                  <h3 style={{marginBottom: '12px'}}>Localização </h3>

                  <div className="map">
                    {
                      isLoaded ? (
                        <GoogleMap
                          mapContainerStyle={{ width: '100%', height: '100%' }}
                          center={{
                            lat: Number(dataProperty?.endereco?.latitude),
                            lng: Number(dataProperty?.endereco?.longitude)
                          }}
                          zoom={14}
                        >
                          <MarkerF
                            position={{
                              lat: Number(dataProperty?.endereco?.latitude),
                              lng: Number(dataProperty?.endereco?.longitude),

                            }}
                            key={dataProperty.id}
                            visible={true}
                            zIndex={10000}
                          // icon={mark}
                          />
                        </GoogleMap>
                      ) :
                        (
                          <></>
                        )
                    }
                  </div>
                  <br />
                </div>

                <div className='relacionados'>
                  <h3 style={{marginBottom: '12px'}}>Relacionados</h3>
                  <Carousel
                    additionalTransfrom={0}
                    arrows
                    autoPlay={true}
                    autoPlaySpeed={3000}
                    centerMode={false}
                    className="conten-cards-imoveis"
                    containerClass="container-with-dots"
                    dotListClass=""
                    draggable
                    focusOnSelect={false}
                    infinite
                    itemClass=""
                    keyBoardControl
                    minimumTouchDrag={80}
                    pauseOnHover
                    renderArrowsWhenDisabled={false}
                    renderButtonGroupOutside={false}
                    renderDotsOutside={false}
                    responsive={{
                      desktop: {
                        breakpoint: {
                          max: 3000,
                          min: 1024
                        },
                        items: 3,
                        partialVisibilityGutter: 40
                      },
                      mobile: {
                        breakpoint: {
                          max: 464,
                          min: 0
                        },
                        items: 1,
                        partialVisibilityGutter: 30
                      },
                      tablet: {
                        breakpoint: {
                          max: 1024,
                          min: 464
                        },
                        items: 2,
                        partialVisibilityGutter: 30
                      }
                    }}
                    rewind={false}
                    rewindWithAnimation={false}
                    rtl={false}
                    shouldResetAutoplay
                    showDots={false}
                    sliderClass="slide"
                    slidesToSlide={1}
                    swipeable
                  >
                    {
                      dataRelacionados.map(item => (
                        <CardInfo
                          key={item.id}
                          id={item.id}
                          descricao={item.descricao}
                          latitude={item.latitude}
                          longitude={item.longitude}
                          valor_aluguel={item.valor_aluguel}
                          valor_venda={item.valor_venda}
                          is_venda={item.is_venda}
                          is_aluguel={item.is_aluguel}
                          periodo_aluguel={item.periodo_aluguel}
                          arquivos={item.arquivos}
                          lat={lat}
                          long={long}
                          onClick={() => navigate(`/imovel/${item.id}`)}
                          idBuilding={item.id}
                          is_fixado={item.is_fixado}
                          is_destaque={item.is_destaque}
                          is_lancamento={item.is_lancamento}
                          bairro={item.bairro}
                          cidade={item.cidade}
                          marginRight='10px'
                        />
                      ))
                    }
                  </Carousel>
                </div>

                <div className='img-and-button'>
                  <img src={'https://portal-nogueira.s3.us-east-2.amazonaws.com/742af626fb8a7c607d80-pessoa-com-notebook.png'} alt="pessoa com o notebook" />
                  <div className='texts'>
                    <p className='text-1'>Financie este imóvel com a caixa</p>
                    <p className='text-2'>parcelas que cabem no seu bolso</p>
                    <div className='button-simulation'>
                      <button>Solicitar financiamento</button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="desc-right">

                <div className='value-property'>
                  <p className='sub'>Valor à vista:</p>
                  <p className='value'>{new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL'
                  }).format(dataProperty.valor_venda ? dataProperty.valor_venda : 0)}</p>
                  {/* <div className='traco' /> */}
                </div>


                <div className="desc-right-wrapper" >

                  <div className="announcement">
                    <div className="broker-information">
                      <div className="image" ><img alt="logo-hub-nogueira" src={'https://portal-nogueira.s3.us-east-2.amazonaws.com/4d4be3a754186d30b83a-logo-hub-nogueira-menor.png'} /></div>
                      <div className="name">
                        <h2 >Hub Nogueira</h2>
                        <p>email@nogueira.com.br</p>
                        <p className='phone'><MdLocalPhone className='avatar-phone' />(81)3019-3333</p>
                      </div>
                    </div>
                  </div>

                  <h4>Gostou do imóvel? Agende uma visita!</h4>
                  <div className="form" style={{padding: '0px'}}>

                    <div className='div-calendar'>
                      <Calendar
                        className='calendar'
                        minDate={new Date()}
                        value={dateReserve}
                        onChange={(v, e) => setDateReserve(v)}
                        locale='pt-BR'

                      />
                    </div>

                    <div className='text-and-check'>
                      <p>Definir horário para visita?</p>
                      <input className='check' type="checkbox" checked={isTime} disabled={loadingContact} onChange={(e) => setIsTime(e.target.checked)} />
                    </div>

                    {
                      isTime &&
                      <input type="time" value={horaReserva} disabled={loadingContact} onChange={(e) => setHoraReserva(e.target.value)} placeholder='Hora da reserva' />
                    }

                    <input type="text" value={nomeCliente} disabled={loadingContact} onChange={(e) => setNomeCliente(e.target.value)} placeholder='Nome Completo' />
                    <input type="text" maxLength={11} disabled={loadingContact} value={maskPhone(contatoCliente1)} onChange={(e) => setContatoCliente1(e.target.value)} placeholder='DDD + Telefone' />
                    {/* <input type="text" maxLength={11} value={maskPhone(contatoCliente2)} onChange={(e) => setContatoCliente2(e.target.value)} placeholder='Telefone 2' /> */}
                    <input type="email" value={emailCliente} disabled={loadingContact} onChange={(e) => setEmailCliente(e.target.value)} placeholder='Digite seu e-mail' />
                    <textarea value={obs} disabled={loadingContact} onChange={(e) => setObs(e.target.value)} placeholder='Observação'></textarea>

                    {/* <h4>Selecione uma data para visita:</h4> */}
                    {/* <input type="date" /> */}

                    {/* <h4>Seleciona a hora:</h4>
                    <input type="time" /> */}

                    <Button
                      label={loadingContact ? 'Enviando' : 'Enviar'}
                      width='100%'
                      height='50px'
                      color='#fff'
                      bgcolor='#0F2445'
                      onclick={handleVisita}
                      mb='1rem'
                      disable={loadingContact}
                    />

                  </div>
                </div>


              </div>
            </section>
          </div>
          <GoToTop />
          <Footer />
        </>
      }


    </Container>
  );
}