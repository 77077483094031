import styled from 'styled-components';

export const Container = styled.div`
  .cat-single {
    width: 100%;
    height: 60px;
    padding: .5rem .8rem;
    border-radius: 1rem;
    font-weight: 500;
    border: 2.5px solid rgba(0,0,0, .1);
    transition: all .3s ease;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    
    &.clicked, &:hover {
      border-color: #0E2244;
      color: #0E2244;
      box-shadow: 0px 2px 4px rgba(0,0,0, .1);
    }
  }
`;