import { Link, useNavigate } from 'react-router-dom';
import { CardInfo } from '../../components/CardInfo';
import { Header, ScrollProps } from '../../components/Header';
import { motion } from 'framer-motion';
import { IoMdArrowDropdown } from 'react-icons/io';
import { GoChevronRight } from 'react-icons/go';
import img01 from '../../assets/cards-news-letter/01.jpg';
import img02 from '../../assets/cards-news-letter/02.jpg';
import img03 from '../../assets/cards-news-letter/03.jpg';
import img04 from '../../assets/cards-news-letter/04.jpg';
import img05 from '../../assets/cards-news-letter/05.jpg';
import img06 from '../../assets/cards-news-letter/06.jpg';
import img07 from '../../assets/cards-news-letter/07.jpg';
import img08 from '../../assets/cards-news-letter/08.jpg';

import {
  Banner,
  Container,
} from './styles';
import { useCallback, useEffect, useState } from 'react';
import { IBuildindCorretor, IPropsBanners, IPropsConstructors } from '../../types';
import { Footer } from '../../components/Footer';
import { LoadSpinner2 } from '../../components/LoadSpinner2';
import { LoadSpinnerMoreCards } from '../../components/LoadSpinnerMoreCards';
import HeaderCategories from '../../components/HeaderCategories';
import { Carousel as Carosel2 } from 'react-responsive-carousel';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import CardNewsLetter from '../../components/CardNewsLetter';
import { useAuth } from '../../context/auth';
import { AdvancedSearch } from '../../components/AdvancedSearch';

export function Home() {
  const navigate = useNavigate();
  const [building, setBuilding] = useState<IBuildindCorretor[]>([]);
  const [buildingTotal, setBuildingTotal] = useState<IBuildindCorretor[]>([]);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const [loadingScroll, setLoadingScroll] = useState(false);
  const [loading, setLoading] = useState(false)
  const [lat, setLat] = useState<number>(0);
  const [long, setLong] = useState<number>(0);
  const [fazerReq, setFazerReq] = useState(true)
  const [constructors, setContructors] = useState<IPropsConstructors[]>([])
  const [banners, setBanners] = useState<IPropsBanners[]>([])

  const { user, api } = useAuth()

  const loadBuildings = useCallback(async () => {
    if (user) {
      setLoading(true)
      await api.get(`portal/building/corret-list-building/${0}?query_search&is_sale&min_sale_price&max_sale_price&is_rent&min_rent_price&max_rent_price&seller&constructor&no_address=1&id_category&is_highlight&id_corretor=${user.id_corretor}`)
        .then((res) => {
          setBuilding(res.data)
          setBuildingTotal(res.data);
          setPage(1)
        })
        .catch(e => console.log(e.response.data.message))
        .finally(() => setLoading(false));
    } else {
      setLoading(true)
      await api.get(`portal/building/list-building/${0}?seller&min_price&max_price&contract_type`)
        .then((res) => {
          setBuilding(res.data)
          setBuildingTotal(res.data);
          setPage(1)
          // console.log(res.data)
        })
        .catch(e => console.log(e.response.data.message))
        .finally(() => setLoading(false));
    }

  }, [user, api]);

  const loadBuildingsScroll = useCallback(async () => {

    if (user) {
      setLoadingScroll(true)
      await api.get(`/portal/building/corret-list-building/${page}?query_search&is_sale&min_sale_price&max_sale_price&is_rent&min_rent_price&max_rent_price&seller&constructor&no_address=1&id_category&is_highlight&id_corretor=${user.id_corretor}`)
        .then((res) => {
          if (res.data.lenght < 12) {
            setFazerReq(false)
          }
          setBuilding(res.data)
          // console.log(res.data)
          setBuildingTotal(prevBuildingTotal => [...prevBuildingTotal, ...res.data]);
        })
        .catch(e => console.log(e.response.data.message))
        .finally(() => setLoadingScroll(false));
    } else {
      setLoadingScroll(true)
      await api.get(`portal/building/list-building/${page}?seller&min_price&max_price&contract_type`)
        .then((res) => {
          if (res.data.lenght < 12) {
            setFazerReq(false)
          }
          setBuilding(res.data)
          // console.log(res.data)
          setBuildingTotal(prevBuildingTotal => [...prevBuildingTotal, ...res.data]);
        })
        .catch(e => console.log(e.response.data.message))
        .finally(() => setLoadingScroll(false));
    }


  }, [page, user, api]);


  const scrollPages = useCallback(() => {
    if (fazerReq) {
      if (page > 0 && building.length > 0) {
        loadBuildingsScroll();
        // console.log(page);
      }
      setPage((pageInsideState) => pageInsideState + 1)
    }

    // console.log(page)
  }, [page, fazerReq, building, loadBuildingsScroll]);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.watchPosition(position => {
        const { latitude, longitude } = position.coords;
        // console.log('lat:',latitude,'long:',longitude)
        setLat(latitude ? latitude : 0);
        setLong(longitude ? longitude : 0);
      });
    } else {
      setLat(0)
      setLong(0)
    }

  }, [])

  const [scrollTop, setScrollTop] = useState<ScrollProps>({ y: 0, lastY: 0 } as ScrollProps);
  const [show, setShow] = useState(false);

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const scrollTop = event.currentTarget.scrollTop;
    setScrollTop(prevState => { return { y: scrollTop, lastY: prevState.y } })
  }

  const loadConstructors = async () => {
    await api.get('/portal/constructors').then(res => {
      setContructors(res.data)
    }).catch(e => console.log(e))
  }

  const loadBanners = async () => {
    await api.get('/portal/banners').then(res => {
      setBanners(res.data)
    }).catch(e => console.log(e))
  }

  useEffect(() => {
    loadConstructors();
    loadBanners();
    loadBuildings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container onScroll={handleScroll}>

      <Header show={show} setShow={setShow} scroll={scrollTop} visibleFilter={true} search={search} setSearch={setSearch} />

      {
        loading &&
        <LoadSpinner2 />
      }
      {
        !loading &&
        <>
          <Banner>

            {/* <div style={{
              position: 'absolute',
              minWidth: '33vw',
              maxWidth: '600px',
              height: 'calc(100% - 120px)',
              margin: '60px',
              zIndex: 100,
              backgroundColor: 'white',
              padding: '40px',
              borderRadius: '12px',
              paddingRight: '30px'
            }}>
              <AdvancedSearch show />
            </div> */}

            <Carosel2
              autoPlay={true}
              interval={5000}
              showThumbs={false}
              infiniteLoop={true}
              showStatus={false}
              onClickItem={(index: number, item: any) => window.open(item?.props?.to, '_blank')}
            >
              {
                banners && banners.map(banner => (
                  <Link key={banner.id} to={banner.url_link} target='_blank'>
                    <img onClick={() => { }} src={banner.url_imagem} alt={banner.descricao} />
                  </Link>
                ))
              }
            </Carosel2>
          </Banner>

          <div style={{ position: 'sticky', top: 0, zIndex: 10 }}>
            <HeaderCategories />
          </div>

          <p className='title-recomendation'>RECOMENDADOS PARA VOCÊ</p>
        </>
      }

      <div className="imoveis">
        {
          !loading &&
          <>
            {buildingTotal && buildingTotal.map((item, index) => {
              return (
                <motion.div
                  key={item.id}
                  initial={{ y: 30, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{
                    type: "spring",
                    damping: 6,
                    mass: .2,
                    stiffness: 150,
                    duration: 1,
                    delay: (index / 30)
                  }}
                >
                  {/* <a className='link-imoveis' href={user ? `perfil/${user.id_corretor}/imovel/${item.id}` : `/imovel/${item.id}`}> */}
                  <CardInfo
                    key={item.id}
                    id={item.id}
                    descricao={item.descricao}
                    latitude={item.latitude}
                    longitude={item.longitude}
                    valor_aluguel={item.valor_aluguel}
                    valor_venda={item.valor_venda}
                    is_venda={item.is_venda}
                    is_aluguel={item.is_aluguel}
                    periodo_aluguel={item.periodo_aluguel}
                    arquivos={item.arquivos}
                    lat={lat}
                    long={long}
                    // onClick={(index:number,item:any)=>window.open(item?.props?.to, '_blank')}
                    onClick={() => window.open(user ? `perfil/${user.id_corretor}/imovel/${item.id}` : `/imovel/${item.id}`, '_blank')}
                    idBuilding={item.id}
                    is_fixado={item.is_fixado}
                    is_destaque={item.is_destaque}
                    is_lancamento={item.is_lancamento}
                    bairro={item.bairro}
                    cidade={item.cidade}
                  // onClick={() => console.log('funciona')}
                  />
                  {/* </a> */}
                </motion.div>
              )
            })
            }
          </>
        }
      </div>
      {
        loadingScroll &&
        <LoadSpinnerMoreCards />
      }
      <div className='div-content-button-view-more'>
        <button disabled={building.length < 12} className='view-more' onClick={scrollPages}>Ver mais <IoMdArrowDropdown className='arrow-view-more' /></button>

      </div>

      <div className='carrosel-construtora'>
        <p className='title-recomendation-2'>CONSTRUTORAS PARCEIRAS</p>
        <div className='content-carrosel'>
          <Carousel
            additionalTransfrom={0}
            arrows
            removeArrowOnDeviceType={['mobile', 'tablet']}
            autoPlay={true}
            autoPlaySpeed={3000}
            centerMode={false}
            className="carroussel-construcors"
            containerClass="container-with-dots"
            dotListClass=""
            draggable
            focusOnSelect={false}
            infinite
            itemClass=""
            keyBoardControl
            minimumTouchDrag={80}
            pauseOnHover
            renderArrowsWhenDisabled={false}
            renderButtonGroupOutside={false}
            renderDotsOutside={false}
            responsive={{
              desktop: {
                breakpoint: {
                  max: 3000,
                  min: 1024
                },
                items: 6,
                partialVisibilityGutter: 40
              },
              mobile: {
                breakpoint: {
                  max: 464,
                  min: 0
                },
                items: 2,
                partialVisibilityGutter: 30
              },
              tablet: {
                breakpoint: {
                  max: 1024,
                  min: 464
                },
                items: 4,
                partialVisibilityGutter: 30
              }
            }}
            rewind={false}
            rewindWithAnimation={false}
            rtl={false}
            shouldResetAutoplay
            showDots={false}
            sliderClass="slide"
            slidesToSlide={2}
            swipeable
          >
            {
              constructors && constructors.map((c) => (
                <div key={c.id} className='image-and-name' onClick={() => navigate(`/imovel/construtora/${c?.id}`)}>
                  <img src={c?.anexo === null ? 'https://portal-nogueira.s3.us-east-2.amazonaws.com/26848b2b91cbd520d366-placeholder-image-nogueira.png' : c?.anexo?.url} alt='construtora' />
                  <p className='name-constructors'>{c.nome}</p>
                </div>
              ))
            }
          </Carousel>
        </div>
      </div>

      <div className='news-letter'>

        <p className='title-recomendation-2'>NOTÍCIAS DO MERCADO IMOBILIÁRIO</p>

        <div className='content-news-letter'>

          <CardNewsLetter
            title='EMPREENDIMENTOS'
            subtitle='Apartamento no planta. Conheça os empreendimentos Miltre Realty'
            img={img01}
          />

          <CardNewsLetter
            title='MERCADO IMOBILIÁRIO'
            subtitle='Guia para vender um imóvel - confira os primeiros passos'
            img={img02}
          />

          <CardNewsLetter
            title='MERCADO IMOBILIÁRIO'
            subtitle='Pensando em alugar um imóvel? tire suas principais dúvidas'
            img={img03}
          />

          <CardNewsLetter
            title='MERCADO IMOBILIÁRIO'
            subtitle='Como comprar apartamento na planta'
            img={img04}
          />

          <CardNewsLetter
            title='EMPREENDIMENTOS'
            subtitle='Apartamento no planta. Conheça os empreendimentos Miltre Realty'
            img={img05}
          />

          <CardNewsLetter
            title='MERCADO IMOBILIÁRIO'
            subtitle='Guia para vender um imóvel - confira os primeiros passos'
            img={img06}
          />

          <CardNewsLetter
            title='MERCADO IMOBILIÁRIO'
            subtitle='Pensando em alugar um imóvel? tire suas principais dúvidas'
            img={img08}
          />

          <CardNewsLetter
            title='MERCADO IMOBILIÁRIO'
            subtitle='Como comprar apartamento na planta'
            img={img07}
          />

        </div>

        <div className='content-news-letter-mobile'>

          <Carosel2
            autoPlay={true}
            interval={4000}
            showThumbs={false}
            infiniteLoop={true}
            showStatus={false}
          // onClickItem={(index:number,item:any)=>window.open(item?.props?.to, '_blank')}
          >
            <CardNewsLetter
              title='EMPREENDIMENTOS'
              subtitle='Apartamento no planta. Conheça os empreendimentos Miltre Realty'
              img={img01}
            />

            <CardNewsLetter
              title='MERCADO IMOBILIÁRIO'
              subtitle='Guia para vender um imóvel - confira os primeiros passos'
              img={img02}
            />

            <CardNewsLetter
              title='MERCADO IMOBILIÁRIO'
              subtitle='Pensando em alugar um imóvel? tire suas principais dúvidas'
              img={img03}
            />

            <CardNewsLetter
              title='MERCADO IMOBILIÁRIO'
              subtitle='Como comprar apartamento na planta'
              img={img04}
            />

            <CardNewsLetter
              title='EMPREENDIMENTOS'
              subtitle='Apartamento no planta. Conheça os empreendimentos Miltre Realty'
              img={img05}
            />

            <CardNewsLetter
              title='MERCADO IMOBILIÁRIO'
              subtitle='Guia para vender um imóvel - confira os primeiros passos'
              img={img06}
            />

            <CardNewsLetter
              title='MERCADO IMOBILIÁRIO'
              subtitle='Pensando em alugar um imóvel? tire suas principais dúvidas'
              img={img08}
            />

            <CardNewsLetter
              title='MERCADO IMOBILIÁRIO'
              subtitle='Como comprar apartamento na planta'
              img={img07}
            />
          </Carosel2>
        </div>

        <div className='button-view-more'>
          <button onClick={() => navigate('/blog-noticias')}>Ver mais notícias <GoChevronRight /></button>
        </div>

      </div>

      <Footer />
    </Container>
  );
}

